<!-- general -->
<template>
  <div class="section-nopad">
    <Section title="Profile" :hasFooter="false" class="mar-b-20">
      <ul class="cardcontent-info">
        <li class="logoItem">
          <div class="lable">Logo</div>
          <div class="info-wrapper">
            <div class="info-wrapper-l">
              <img class="logo-img" :src="logoImg" alt="" />
            </div>
          </div>
          <i class="iconfont iconedit" @click="uploadHeaderImg('logoImg')"></i>
        </li>
        <li>
          <div class="lable">Company info</div>
          <div class="info-wrapper">
            <ul class="info-wrapper-default">
              <li>
                <div class="info-wrapper-l">Name</div>
                <div class="info-wrapper-r">{{ companyInfo.companyName }}</div>
              </li>
              <li>
                <div class="info-wrapper-l">Registration no.</div>
                <div class="info-wrapper-r">
                  {{ companyInfo.registrationNo }}
                </div>
              </li>
              <li>
                <div class="info-wrapper-l">Address</div>
                <div class="info-wrapper-r">
                  <div>
                    {{ `${companyInfo.address}${companyInfo.addressLine2}` }}
                  </div>
                  <div>
                    {{ `${companyInfo.country}${companyInfo.postalCode}` }}
                  </div>
                </div>
              </li>
              <li>
                <div class="info-wrapper-l">Email</div>
                <div class="info-wrapper-r">{{ companyInfo.email }}</div>
              </li>
              <li>
                <div class="info-wrapper-l">Phone</div>
                <div class="info-wrapper-r">
                  {{ companyInfo.countryCode }}{{ companyInfo.phoneNumber }}
                </div>
              </li>
            </ul>
          </div>
          <i class="iconfont iconedit" @click="toCompanyInfo"></i>
        </li>
      </ul>
    </Section>
    <Section :title="title" :hasFooter="false">
      <ul class="cardcontent-info">
        <li v-for="(item, index) in sectionData" :key="index">
          <div class="lable">{{ item.label }}</div>
          <i class="iconfont iconedit" @click="handleEdit(item)"></i>
        </li>
      </ul>
    </Section>
    <img-cut-modal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      :fixed-number="[37,18]"
      @_uploadCallback="_uploadCallback"
	  fixed
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      activeIndex: "settingGeneral",
      logoImg: "",
      isImgCutShow: false,
      title: "General",
      dataList: [
        { label: "Nationality", value: "nationality", type: "0" },
        { label: "Race", value: "race", type: "1" },
        { label: "Residential status", value: "residentialStatus", type: "1" },
        { label: "Country", value: "country", type: "0" },
      ],
      companyInfo: {
        companyName: "",
        registrationNo: "",
        address: "",
        addressLine2: "",
        email: "",
        tel: "",
        country: "",
        postalCode: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
    sectionData() {
      let data = [];
      if (this.user.userType === "0") {
        data = this.dataList.filter((item) => item.type === "0");
      } else {
        data = this.dataList;
      }
      return data;
    },
  },
  methods: {
    _getData() {
      var data = {
        userId: this.user.userId,
      };
      this.loading = true;
      Ajax.get("/usermanage/company/selectCompanyByUserId", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this.companyInfo = { ...response };
          this.logoImg = response.logo;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _uploadCallback(formData, fileData) {
      console.log(formData, fileData);
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (this.imgType == "logoImg") {
            this.logoImg = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          }
          this.submitEdit();
          this.isImgCutShow = false;
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    uploadHeaderImg(type) {
      this.isImgCutShow = true;
      this.imgType = type;
    },
    toCompanyInfo() {
      this.$router.push({
        name: "settingCompanyInfo",
        query: { id: this.companyInfo.id },
      });
    },
    submitEdit() {
      var formData = {
        userId: this.user.userId,
        id: this.companyInfo.id,
        logo: this.logoImg,
      };
      this.loading = true;
      Ajax.post("/usermanage/company/updateCompany", formData).then((res) => {
        let response = res.data;
        this.loading = false;
      });
    },
    handleEdit(item) {
      this.$router.push({
        name: "tablePage",
        query: {
          title: item.label,
          moduleType: item.value,
          moduleName: "general",
          activeIndex: "settingGeneral",
        },
      });
    },
  },
  created() {
    this._getData();
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.logo-img {
  height: 132px;
}
</style>